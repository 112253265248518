'use strict';
angular.module("aerosApp")
	.factory('viewFileCollectionModal', [ "$uibModal", function ($uibModal) {

		function open(configObj) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/project/viewFileCollectionModal.html",
				controller: "viewFileCollectionModalCtrl",
				resolve: {
					configObj: function () { return configObj; }
				}
			});
			return modal.result;
		}
		return {
			open: open
		};
	}])
	.controller("viewFileCollectionModalCtrl",
			[ "$scope", "$uibModalInstance", "aerosApi", "Notification", "$uibModal", "configObj",
			  function($scope, $uibModalInstance, aerosApi, Notification, $uibModal, configObj) {
				$scope.submitLabel = "Save";
				$scope.project = configObj.project;
				$scope.orgId = configObj.orgId;

				$scope.level = configObj.level;
				$scope.obj = configObj.obj;
				$scope.parent = configObj.parent;

				function setFiberShowId() {
					angular.forEach($scope.obj.fibers, function(fiber) {
						fiber.fiberShowId = +fiber.fiberId + ($scope.parent.startFiber
							&& +$scope.parent.startFiber > 0 ? +$scope.parent.startFiber - 1 : 0);
					});
				}

                setFiberShowId();

				$scope.refresh = function () {
					var config = {
							orgId: $scope.orgId,
							projectId: $scope.project.id,
							collectionId: $scope.obj.id
					};
					aerosApi.getAttachmemtCollectionDetails(config).then(function (rtn) {

						//bla
						var fiberCount = $scope.obj.fibers.length;
						var fibers = fiberCount ? new Array(fiberCount) : new Array();
						_.each(fibers, function (noop, counter) {
							var i = counter + 1;
							var orig = _.find(rtn.data.collection.fibers, function(f) {
								return Number(f.fiberId) === i;
							});
							fibers[counter] = orig ? orig :{fiberId: i, attachments: []};
						});
						//bla

						//console.log("collection RTN", rtn);
						$scope.obj = rtn.data.collection;
						$scope.obj.fibers = fibers;
                        setFiberShowId();

                    }, function (err) {
						//console.log("collection ERR", err);
						Notification.error("Error Updating collection");
					});
					configObj.refresh();
				};

				$scope.attachment = {};

				$scope.formData = {
						selectedLinkLevel: $scope.level
				};
				if ($scope.level !== "Project") {
					$scope.formData.checkedIds = {};
					$scope.formData.checkedIds[$scope.obj.id] = true;
				}

				$scope.editCollection = function () {
					$uibModal.open({
                        size: "md",
						templateUrl: '/static/templates/dashboard/project/modal/addEditCollectionModal.html',
						resolve: {
							level: function () { return $scope.level; },
							obj: function () { return $scope.parent; },
							collection: function () { return $scope.obj; }
						},
						controller: ["$scope", "$uibModalInstance", "level", "obj", "collection", function($scope, $uibModalInstance, level, obj, collection) {
							$scope.mode = "Edit";
							$scope.level = level;
							$scope.obj = obj;
							$scope.name = collection.name;
							$scope.saveCollection = function () {
								if (_.isEmpty($scope.name)) {
									return;
								}
								$uibModalInstance.close($scope.name);
							};
						}]
					}).result.then(function (collectionName) {
						var config = {
								orgId: $scope.orgId,
								projectId: $scope.project.id,
								collectionId: $scope.obj.id,
								name: collectionName
						};
						aerosApi.updateAttachmentCollectionName(config).then(function (rtn) {
							//console.log("Update collection RTN", rtn);
							Notification.success("Successfully Updated collection");
							$scope.obj.name = rtn.data.collection.name;
							// ? returns .attachments: [{key: {raw: {id: ###, kind: "Attachment"
							$scope.refresh();
						}, function (err) {
							//console.log("Error Updating collection", err);
							Notification.error("Error Updating collection");
						});
					});
				};

				$scope.addAttachment = function(level, obj, fiberId) {
					$uibModal.open({
                        size: "md",
						templateUrl: '/static/templates/dashboard/project/modal/addEditAttachmentModal.html',
						resolve: {
							level: function () { return level; },
							obj: function () { return obj; },
							fiberId: function () { return fiberId; }
						},
						controller: ["$scope", "$uibModalInstance", "level", "obj", "fiberId", function($scope, $uibModalInstance, level, obj, fiberId) {
							$scope.mode = "Add";
							$scope.target = obj;
							$scope.level = level;
							$scope.fiberId = fiberId;
							$scope.saveAttachment = function() {
								$uibModalInstance.close({
									"attachment": $scope.attachment,
									"fiberId": $scope.fiberId
								});
							};
						}]
					}).result.then(function (data) {
						var attachmentObj = data.attachment;
						attachmentObj.orgId = $scope.orgId;
						attachmentObj.projectId = $scope.project.id;
						attachmentObj.collectionId = $scope.obj.id;
						if (data.fiberId) {
							attachmentObj.fiberId = data.fiberId;
						}
						attachmentObj.fileName = attachmentObj.file.name;
						aerosApi.addAttachmentToCollection(attachmentObj).then(function (rtn) {
							//console.log("Attach RTN", rtn);
							Notification.success("Successfully attached the file");
							obj.attachments = obj.attachments || [];
							obj.attachments.push(rtn.data.attachment);
							$scope.refresh();
						}, function (err) {
							//console.log("Attach ERR", err);
							Notification.error("Error attaching the file");
						});
					});
				};
				$scope.deleteAttachment = function(attachment, fiberId) {
					$uibModal.open({
                        size: "md",
						templateUrl: '/static/templates/dashboard/project/modal/confirmDeleteModal.html',
						resolve: {
							attachment: function () { return angular.copy(attachment); },
							fiberId: function () { return fiberId; }
						},
						controller: ["$scope", "attachment", "fiberId", function($scope, attachment, fiberId) {
							attachment.name = attachment.fileName;
							$scope.entity = "Attachment";
							$scope.list = [attachment];
							$scope.fiberId = fiberId;
						}]
					}).result.then(function (fiberId) {
						var config = {
							orgId: $scope.orgId,
							projectId: $scope.project.id,
							collectionId: $scope.obj.id,
							attachmentId: attachment.id
						};
						if (fiberId) {
							config.fiberId = fiberId;
						}
						if ($scope.level === "Fibergroup") {
							config.fiberGroupId = $scope.obj.fiberGroupId;
						}
						return aerosApi.deleteAttachment(config).then(function (rtn) {
							//console.log('Delete file RTN', rtn);
							Notification.success("Successfully deleted file from collection.");
							$scope.refresh();
							// TODO: remove from popup list too – have to get collection?
						}, function (err) {
							//console.log('Delete file ERR', err);
							Notification.error("There was a problem deleting the file from the collection.");
						});
					});
				};
				$scope.editAttachment = function(level, obj, attachment, fiberId) {
					$uibModal.open({
                        size: "md",
						templateUrl: '/static/templates/dashboard/project/modal/addEditAttachmentModal.html',
						resolve: {
							level: function () { return level; },
							obj: function () { return obj; },
							attachment: function () { return attachment; },
							fiberId: function () { return fiberId; }
						},
						controller: ["$scope", "$uibModalInstance", "level", "obj", "attachment", "fiberId", function($scope, $uibModalInstance, level, obj, attachment, fiberId) {
							$scope.mode = "Edit";
							$scope.target = obj;
							$scope.level = level;
							$scope.fiberId = fiberId;
							$scope.attachment = {
									comment: attachment.comment,
									fileName: attachment.fileName,
									attachmentId: attachment.id
							};
							$scope.saveAttachment = function() {
								$uibModalInstance.close($scope.attachment);
							};
						}]
					}).result.then(function (attachmentObj) {
						attachmentObj.orgId = $scope.orgId;
						attachmentObj.projectId = $scope.project.id;
						attachmentObj.collectionId = $scope.obj.id;
						if (attachmentObj.file) {
							attachmentObj.fileName = attachmentObj.file.name;
						}
						if ($scope.fiberId) {
							attachmentObj.fiberId = $scope.fiberId;
						}
						aerosApi.updateAttachment(attachmentObj).then(function (rtn) {
							//console.log("Edit Attach RTN", rtn);
							Notification.success("Successfully updated the attachment");
							$scope.refresh();
						}, function (err) {
							//console.log("Edit Attach ERR", err);
							Notification.error("Error updating attachment");
						});
					});
				};
				$scope.getRepeater = function (n) {
					var num = +n || 0;
					return new Array(num);
				};
				$scope.getFiberNum = function (start,i) {
					return Number(start) + Number(i);
				};

				$scope.cancel = function() {
					$uibModalInstance.dismiss();
				};
				$scope.save = function() {
					if ($scope.formData.selectedLinkLevel !== 'Project') {
						$scope.attachment.ids = [];
						_.each($scope.formData.checkedIds, function (isChecked, id) {
							if (isChecked) {
								$scope.attachment.ids.push(id);
							}
						});
						if (!$scope.attachment.ids.length) {
							return;
						}
					}
					$scope.attachment.entityType = $scope.formData.selectedLinkLevel;
					$uibModalInstance.close($scope.attachment);
				};
	}]);
